import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import * as option from "@/models/configurator/configurator_options";
import { FabricSwatch, FixedFabricArray } from "@/models/products/fabric";
import { LayoutStore, CanopyStore } from "@/mixins/store";
import FabricService from "@/services/fabric_service";
import { ProductLineName } from "@/models/products/collection";
import { FabricRules } from "../global/fabric_rules";
import { ConfigFile } from "@/models/configurator/config_file";
import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import { ConfigCreator } from "@/mixins/configurator";

@Component
export class LayoutRules extends mixins(
  LayoutStore,
  CanopyStore,
  FabricRules,
  ConfigCreator
) {
  protected mainFabricWeight: string | undefined;
  protected whiteFabricMfrCode = '';
  protected greyFabricMfrCode = '';
  protected whiteFabric: null | FabricSwatch = null;
  protected ProductLineName = ProductLineName;
  protected fabricService = new FabricService();

  /**
   * Called from Layout.vue on user selecting new layout, or from FabricSelector.vue on user selecting fabric swatch.
   *
   */
  async created() {
    await this.getWhiteFabric();
  }

  protected async getWhiteFabric(): Promise<void> {
    this.mainFabricWeight = this.mainFabric[0].weight;
    switch (this.mainFabricWeight) {
      case ('8') :
        this.whiteFabricMfrCode = '57003';
        this.greyFabricMfrCode = '40483';
        break;
      case ('9') :
        this.whiteFabricMfrCode = 'R-099';
        this.greyFabricMfrCode = 'R-164';
        break;
    }
    const res = await this.fabricService.getSingleFabric(this.whiteFabricMfrCode);
    this.whiteFabric = res;
  }

  /**
   * This function is called upon the following components:
   * - FabricSelector -- whenever a fabric is selected
   * - LayoutSidebar -- whenever a layout is selected
   */
  protected async applyLayoutRules(isLayoutChange = false): Promise<void> {
    await this.getWhiteFabric();
    if (isLayoutChange) {
      const prevPresetCanopyDataStr = localStorage.getItem(`shadeDesigner-${this.preset}`);
      if (prevPresetCanopyDataStr) {
        const canopyData = JSON.parse(prevPresetCanopyDataStr) as CustomUmbrella["canopy"];
        await this.addAllCanopyData(canopyData);
        // main canopy fabric
        if (this.mainFabric.length === 1) {
          await this.$viewer.ChangeCanopyFabric(
            this.mainFabric[0].fabric_scale,
            this.mainFabric[0].mfr_code
          );
        } else {
          await this.$viewer.ChangeCanopyFabric(
            this.mainFabric[0].fabric_scale,
            this.mainFabric[0].mfr_code,
            this.mainFabric[1].fabric_scale,
            this.mainFabric[1].mfr_code
          );
        }
        // top vent
        if (this.ventTopFabric.length > 0) {
          this.$viewer.ChangeTopVentFabric(
            this.ventTopFabric[0].fabric_scale,
            this.ventTopFabric[0].mfr_code
          );
        }
        // middle vent
        if (this.ventMiddleFabric.length === 1) {
          this.$viewer.ChangeDblVentFabric(
            this.ventMiddleFabric[0].fabric_scale,
            this.ventMiddleFabric[0].mfr_code
          );
        } else if (this.ventMiddleFabric.length === 2) {
          this.$viewer.ChangeDblVentFabric(
            this.ventMiddleFabric[0].fabric_scale,
            this.ventMiddleFabric[0].mfr_code,
            this.ventMiddleFabric[1].fabric_scale,
            this.ventMiddleFabric[1].mfr_code
          );
        }
        // trims or valance & binding fabrics
        if (
          this.preset === option.Preset.D1 ||
          this.preset === option.Preset.D2 ||
          this.preset === option.Preset.D3
        ) {
          await this.$viewer.ChangeValance(option.Valance.Canopy);
          await this.$viewer.ChangeValanceFabric(null);
          await this.$viewer.ChangeBindingFabric(null);
          await this.$viewer.ChangeCanopyTrim(
            this.mainTrimFabric!.mfr_code,
            this.mainTrimFabricInner ? this.mainTrimFabricInner.mfr_code : null
          );
        } else {
          if (
            this.preset === option.Preset.S1 ||
            this.preset === option.Preset.S1DV
          ) {
            await this.$viewer.ChangeValance(this.valance);
            if (this.valance === option.Valance.Canopy) {
              await this.$viewer.ChangeValanceFabric(null);
              await this.$viewer.ChangeBindingFabric(null);
            } else {
              await this.$viewer.ChangeValanceFabric(
                this.valanceFabric[0].fabric_scale,
                this.valanceFabric[0].mfr_code,
                this.valanceFabric.length > 1
                  ? this.valanceFabric[1].fabric_scale
                  : null,
                this.valanceFabric.length > 1
                  ? this.valanceFabric[1].mfr_code
                  : null
              );
              await this.$viewer.ChangeBindingFabric(
                this.bindingFabric ? this.bindingFabric.mfr_code : null
              );
            }
          }
        }
        return;
      }
    }

    // if double-vented & fabric weight is not the equal to the main canopy fabric weight, need to set default middle vent fabric
    if (
      this.preset === option.Preset.S1DV ||
      this.preset === option.Preset.D5
    ) {
      if (
        !this.ventMiddleFabric[0] ||
        this.ventMiddleFabric[0] && this.ventMiddleFabric[0].weight !== this.mainFabricWeight
      ) {
        await this.addVentMiddle(option.Panel.Solid);
        await this.addVentMiddleFabric([this.mainFabric[0]]);
      }
      if (
        this.ventMiddleFabric[1] && this.ventMiddleFabric[1].weight !== this.ventMiddleFabric[0].weight
        ) {
          await this.addVentMiddle(option.Panel.Solid);
          await this.addVentMiddleFabric([this.ventMiddleFabric[0]]);
      }
    } else {
      await this.addVentMiddleFabric([]);
    }
    // designer layouts do not have valance or alternating panels
    if (
      this.preset !== option.Preset.S1 &&
      this.preset !== option.Preset.S1DV
    ) {
      await this.removeValance();
      await this.makeCanopiesSolid();
      await this.removeBindingFabric();
    } else {
      await this.clearAllTrim();
    }
    /**
     * The following switch sets preset configurations of designer umbrellas
     *
     * Each designer layout offers a different combination of trim fabric locations
     *
     * The goal is for the user to see a visual change on the umbrella when they toggle between different designer presets, so this logic
     * sets all trim fabric to a preset selection determined by the weight of the main canopy fabric and then saves these changes to the store:
     *    - 8oz weight: Canvas White | 57003 | Sunbrella | Grade B (or Cast Charcoal | 40483 | Sunbrella | Grade B if Main Canopy Fabric is white)
     *    - 9oz weight: White | R-099 | Recasens | Grade A (or Charcoal Grey | R-164 | Recasens | Grade A if Main Canopy Fabric is white)
     *
     * If the trim fabric is already set with the same weight as the Main Canopy Fabric, do nothing except clear the non-applicable trim fabric data.
     * If the trim fabric is already set with a different weight than the Main Canopy Fabric, reset the trim fabric to a compatible fabric with a corresponding weight.
     *
     * D2 & D3 differ in the viewer display, but have the same data
     *
     */
    switch (this.preset) {
      case option.Preset.D1: {
        if (isLayoutChange) {
          await this.setDesignerLayoutMainCanopyFabric();
        }
        this.addMainTrimFabricInner(null);
        this.addVentTrimFabric(null);
        if (!this.mainTrimFabric || this.mainTrimFabric.weight !== this.mainFabricWeight) {
          // only execute if trim fabric is not set or if the trim weight is different from the main canopy fabric
          let swatch = this.whiteFabric!;
          if (this.mainFabric[0].mfr_code === this.whiteFabric!.mfr_code) {
            const res = await this.fabricService.getSingleFabric(this.greyFabricMfrCode);
            swatch = res;
          }
          await this.addMainTrimFabric(swatch);
          await this.$viewer.ChangeCanopyTrim(swatch.mfr_code, null);
        }
        break;
      }
      case option.Preset.D2:
      case option.Preset.D3: {
        if (isLayoutChange) {
          await this.setDesignerLayoutMainCanopyFabric();
        }
        this.addVentTrimFabric(null);
        if (!this.mainTrimFabric || this.mainTrimFabric.weight !== this.mainFabricWeight) {
          // only execute if trim fabric is not set or if the trim weight is different from the main canopy fabric
          let swatchOuter = this.whiteFabric!;
          if (this.mainFabric[0].mfr_code === this.whiteFabric?.mfr_code) {
            const res = await this.fabricService.getSingleFabric(this.greyFabricMfrCode);
            swatchOuter = res;
          }
          await this.addMainTrimFabric(swatchOuter);
        }
        if (!this.mainTrimFabricInner || this.mainTrimFabricInner.weight !== this.mainFabricWeight) {
          // only execute if trim fabric is not set or if the trim weight is different from the main canopy fabric
          let swatchInner = this.whiteFabric!;
          if (this.mainFabric[0].mfr_code === this.whiteFabric?.mfr_code) {
            const res = await this.fabricService.getSingleFabric(this.greyFabricMfrCode);
            swatchInner = res;
          }
          await this.addMainTrimFabricInner(swatchInner);
        }
        await this.$viewer.ChangeCanopyTrim(
          this.mainTrimFabric!.mfr_code,
          this.mainTrimFabricInner!.mfr_code
        );
        break;
      }
      case option.Preset.D4: {
        await this.addMainTrimFabric(null);
        await this.addMainTrimFabricInner(null);
        if (!this.ventTrimFabric || this.ventTrimFabric.weight !== this.mainFabricWeight) {
          // only execute if trim fabric is not set or if the trim weight is different from the main canopy fabric
          let swatch = this.whiteFabric!;
          if (this.ventTopFabric[0].mfr_code === this.whiteFabric!.mfr_code) {
            const res = await this.fabricService.getSingleFabric(this.greyFabricMfrCode);
            swatch = res;
          }
          await this.addVentTrimFabric(swatch);
          await this.$viewer.ChangeVentTrim(this.ventTrimFabric!.mfr_code);
        }
        break;
      }
      case option.Preset.D5: {
        await this.addMainTrimFabricInner(null);
        if (!this.ventTrimFabric || this.ventTrimFabric.weight !== this.mainFabricWeight) {
          // only execute if trim fabric is not set or if the trim weight is different from the main canopy fabric
          let swatch = this.whiteFabric!;
          if (this.ventTopFabric[0].mfr_code === this.whiteFabric!.mfr_code) {
            const res = await this.fabricService.getSingleFabric(this.greyFabricMfrCode);
            swatch = res;
          }
          await this.addVentTrimFabric(swatch);
          await this.$viewer.ChangeVentTrim(this.ventTrimFabric!.mfr_code);
        }
        if (!this.mainTrimFabric || this.mainTrimFabric.weight !== this.mainFabricWeight) {
          // only execute if trim fabric is not set or if the trim weight is different from the main canopy fabric
          let swatch = this.whiteFabric!;
          if (this.mainFabric[0].mfr_code === this.whiteFabric!.mfr_code) {
            const res = await this.fabricService.getSingleFabric(this.greyFabricMfrCode);
            swatch = res;
          }
          await this.addMainTrimFabric(swatch);
          await this.$viewer.ChangeCanopyTrim(
            this.mainTrimFabric!.mfr_code,
            null
          );
        }
        break;
      }
    }
  }

  protected async removeBindingFabric(): Promise<void> {
    await this.addBindingFabric(null);
    await this.$viewer.ChangeBindingFabric(null);
  }

  protected async removeValance(): Promise<void> {
    await this.addValance(option.Valance.Canopy);
    await this.$viewer.ChangeValance(option.Valance.Canopy);
  }

  protected async makeCanopiesSolid(): Promise<void> {
    await this.addMainFabric([this.mainFabric[0]]);
    await this.$viewer.ChangeCanopyFabric(
      this.mainFabric[0].fabric_scale,
      this.mainFabric[0].mfr_code
    );
    await this.addVentMiddle(option.Panel.Solid);
    await this.addMainCanopy(option.Panel.Solid);
  }

  protected async clearAllTrim(): Promise<void> {
    await this.addMainTrimFabric(null);
    await this.addMainTrimFabricInner(null);
    await this.addVentTrimFabric(null);
  }

  protected async setDesignerLayoutMainCanopyFabric(): Promise<void> {
    const hasStripes = await this.checkModelForStripes();
    if (!hasStripes) return; // keep the fabrics the same
    const S1CanopyStr = localStorage.getItem(
      `shadeDesigner-${option.Preset.S1}`
    );
    const S1DVCanopyStr = localStorage.getItem(
      `shadeDesigner-${option.Preset.S1DV}`
    );
    if (!S1CanopyStr && !S1DVCanopyStr) {
      await this.useDefaultCanopyFabricForDesignerLayout();
      return;
    }
    const S1CanopyObj = S1CanopyStr
      ? (JSON.parse(S1CanopyStr) as CustomUmbrella["canopy"])
      : null;
    const S1CanopyFabrics = S1CanopyObj
      ? [
          ...S1CanopyObj.mainFabric,
          ...S1CanopyObj.ventTopFabric,
          ...S1CanopyObj.ventMiddleFabric
        ]
      : [];
    const isS1CanopyStriped = S1CanopyObj
      ? S1CanopyFabrics.some(swatch => {
          return this.checkForStripes(swatch);
        })
      : false;
    if (isS1CanopyStriped) {
      // if stripes:
      // check for usage of S1DV instead
      const S1DVCanopyObj = S1DVCanopyStr
        ? (JSON.parse(S1DVCanopyStr) as CustomUmbrella["canopy"])
        : null;
      const S1DVCanopyFabrics = S1DVCanopyObj
        ? [
            ...S1DVCanopyObj.mainFabric,
            ...S1DVCanopyObj.ventTopFabric,
            ...S1DVCanopyObj.ventMiddleFabric
          ]
        : [];
      const isS1DVCanopyStriped = S1DVCanopyObj
        ? S1DVCanopyFabrics.some(swatch => {
            return this.checkForStripes(swatch);
          })
        : false;
      if (isS1DVCanopyStriped || !S1DVCanopyObj) {
        await this.useDefaultCanopyFabricForDesignerLayout();
      } else {
        await this.usePreConfiguredCanopyFabricForDesignerLayout(
          S1DVCanopyObj.mainFabric
        );
      }
    } else {
      if (S1CanopyObj) {
        await this.usePreConfiguredCanopyFabricForDesignerLayout(
          S1CanopyObj.mainFabric
        );
      } else {
        await this.useDefaultCanopyFabricForDesignerLayout();
      }
    }
  }

  protected async usePreConfiguredCanopyFabricForDesignerLayout(
    fabricArray: FixedFabricArray
  ): Promise<void> {
    await this.addMainFabric(fabricArray);
    if (fabricArray.length === 1) {
      await this.$viewer.ChangeCanopyFabric(
        fabricArray[0].fabric_scale,
        fabricArray[0].mfr_code
      );
    } else {
      await this.$viewer.ChangeCanopyFabric(
        fabricArray[0].fabric_scale,
        fabricArray[0].mfr_code,
        fabricArray[1].fabric_scale,
        fabricArray[1].mfr_code
      );
    }
    await this.addVentTopFabric(fabricArray);
    await this.$viewer.ChangeTopVentFabric(
      fabricArray[0].fabric_scale,
      fabricArray[0].mfr_code
    );
  }

  protected async useDefaultCanopyFabricForDesignerLayout(): Promise<void> {
    const basicSwatch = await this.getFabricSwatch("R-196"); // Grade A Recasens Titanium (Grey color);
    const defaultCanopyStr = localStorage.getItem(
      `shadeDesigner-defaultCanopy`
    );
    let swatch = basicSwatch;
    if (defaultCanopyStr) {
      const defaultCanopyObj = JSON.parse(
        defaultCanopyStr
      ) as ConfigFile["canopy"];
      swatch = await this.getFabricSwatch(defaultCanopyObj.mainFabric[0]);
    }
    await this.addMainFabric([swatch]);
    await this.$viewer.ChangeCanopyFabric(swatch.fabric_scale, swatch.mfr_code);
    await this.addVentTopFabric([swatch]);
    await this.$viewer.ChangeTopVentFabric(
      swatch.fabric_scale,
      swatch.mfr_code
    );
  }
}
